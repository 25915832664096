import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonContent, ModalController } from '@ionic/angular/standalone';
import { TranslatePipe, _ } from '@ngx-translate/core';
import { LoadingUtils } from 'src/app/utils/loading.utils';
import { PasswordService } from '../../services/password.service';
import { ToastService } from '../../services/toast.service';
import { EmailValidator } from '../../validators/email.validator';
import { passwordConfirmValidator, passwordFormatValidator } from '../../validators/password.validator';

@Component({
    selector: 'app-password-lost-modal',
    templateUrl: './password-lost-modal.component.html',
    styleUrls: ['./password-lost-modal.component.scss'],
    imports: [CommonModule, IonContent, TranslatePipe, ReactiveFormsModule, CommonModule],
})
export class PasswordLostModalComponent implements OnInit {
    @Input() email: string;
    token: string;
    form: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required, EmailValidator]),
    });

    constructor(
        private readonly modalCtrl: ModalController,
        private readonly passwordService: PasswordService,
        private readonly toastService: ToastService,
    ) {}

    ngOnInit() {
        if (this.email) {
            // Auto send request
            this.form.get('email').setValue(this.email);
            this.next();
        }
    }

    addPasswordFields() {
        this.form.get('email').disable();
        this.form.addControl('code', new FormControl('', [Validators.required]));
        this.form.addControl('password', new FormControl('', [Validators.required, passwordFormatValidator]));
        this.form.addControl('passwordConfirm', new FormControl('', [Validators.required]));
        this.form.addControl('token', new FormControl(this.token, [Validators.required]));
        this.form.addValidators(passwordConfirmValidator);
    }

    async getToken() {
        await LoadingUtils.show();
        this.passwordService.resetPassword(this.form.value).subscribe({
            next: (response) => {
                this.token = response.token;
                LoadingUtils.hide();
                this.addPasswordFields();
                this.toastService.success(_('Code was send to your email address'));
            },
            error: (err) => {
                LoadingUtils.hide();
                console.error(err);
                this.toastService.error(_('An error occurred'));
            },
        });
    }

    async changePassword() {
        await LoadingUtils.show();
        this.passwordService.setNewPassword(this.form.value).subscribe({
            next: () => {
                this.modalCtrl.dismiss();
                LoadingUtils.hide();
                this.toastService.success(_('Password was changed'));
            },
            error: (err) => {
                console.error(err);
                LoadingUtils.hide();
                this.toastService.error(_('An error occurred'));
            },
        });
    }

    cancel() {
        this.modalCtrl.dismiss();
    }

    next() {
        if (this.token) {
            this.changePassword();
        } else {
            this.getToken();
        }
    }
}
