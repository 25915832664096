import { AbstractControl } from '@angular/forms';

export function passwordConfirmValidator(control: AbstractControl) {
    // Make sure that password and passwordConfirm are the same
    if (control.value.password !== control.value.passwordConfirm) {
        control.get('passwordConfirm').setErrors({ passwordConfirm: true });
        return { passwordConfirm: true };
    }

    return null;
}

export function passwordFormatValidator(control: AbstractControl) {
    const password = control.value;
    if (password.length < 8) {
        return { invalidPassword: true };
    }

    // At least one uppercase letter
    if (!/[A-Z]/.test(password)) {
        return { invalidPassword: true };
    }

    // At least one lowercase letter
    if (!/[a-z]/.test(password)) {
        return { invalidPassword: true };
    }

    // At least one number
    if (!/[0-9]/.test(password)) {
        return { invalidPassword: true };
    }

    // At least one special character
    if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) {
        return { invalidPassword: true };
    }

    return null;
}
