import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { _ } from '@ngx-translate/core';
import { DefaultModalComponent } from '../modals/default-modal/default-modal.component';
import { ModalContent } from '../models/modal-content';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    constructor(private readonly modalCtrl: ModalController) {}

    async create(content: ModalContent): Promise<HTMLIonModalElement> {
        content.title = content.title || _('Oops!');
        content.message = content.message || _('An error occurred');
        content.code = content.code || 0;
        content.confirmText = content.confirmText || _('OK');
        content.class = content.class || 'error-modal';

        const modal = await this.modalCtrl.create({
            component: DefaultModalComponent,
            componentProps: {
                content: content,
            },
            cssClass: content.class,
        });

        await modal.present();
        await modal.onWillDismiss().then((resultData) => {
            if (content.callback) {
                content.callback(resultData.data);
            }
        });

        return modal;
    }
}
