import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular/standalone';
import { TranslatePipe } from '@ngx-translate/core';
import { ModalContent } from '../../models/modal-content';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './default-modal.component.html',
    styleUrls: ['./default-modal.component.scss'],
    imports: [CommonModule, IonContent, TranslatePipe, CommonModule],
})
export class DefaultModalComponent implements OnInit {
    @Input() content: ModalContent;

    constructor(private readonly modalCtrl: ModalController) {}

    ngOnInit() {
        console.log(this.content);
    }

    close(action: string) {
        this.modalCtrl.dismiss({ action });
    }
}
