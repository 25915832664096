import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

export type StorageKey = 'locale' | 'redirectAfterLogin' | 'shopping-cart';

@Injectable({
    providedIn: 'root',
})
export class PasswordService {
    constructor(private readonly apiService: ApiService) {}

    resetPassword(email: string) {
        return this.apiService.post<{ token: string }>('/api/reset-password', {
            email,
        });
    }

    setNewPassword(data: { token: string; password: string; passwordConfirm: string }) {
        return this.apiService.post<{ success: boolean }>('/api/reset-password/change', data);
    }
}
