<ion-content>
    <div class="modal-inner-content p-4">
        <div class="d-flex justify-content-end w-100">
            <span class="bi bi-x fs-3 clickable" (click)="cancel()"></span>
        </div>
        <h3>{{ "Reset your password" | translate }}</h3>
        <p>
            {{ "Reset your password description" | translate }}
        </p>
        <form (submit)="next()" [formGroup]="form">
            <!-- Email -->
            <div class="form-group">
                <label class="text-dark">{{ "Email" | translate }}</label>
                <input
                    type="email"
                    class="form-control"
                    formControlName="email"
                    [ngClass]="{ 'is-invalid': form.get('email').touched && form.get('email').invalid }"
                />
            </div>
            <!-- Code -->
            <div class="form-group" *ngIf="form.get('code')">
                <label class="text-dark">{{ "Code" | translate }}</label>
                <input
                    type="text"
                    class="form-control"
                    formControlName="code"
                    [ngClass]="{ 'is-invalid': form.get('code').invalid && form.get('code').touched }"
                />
            </div>
            <!-- Password -->
            <div class="form-group" *ngIf="form.get('password')">
                <label class="text-dark">{{ "New password" | translate }}</label>
                <input
                    type="password"
                    class="form-control"
                    formControlName="password"
                    [ngClass]="{ 'is-invalid': form.get('password').invalid && form.get('password').touched }"
                />
                <small>{{ "Password requirements" | translate }}</small>
            </div>
            <!-- Password confirm -->
            <div class="form-group" *ngIf="form.get('passwordConfirm')">
                <label class="text-dark">{{ "Password confirm" | translate }}</label>
                <input
                    type="password"
                    class="form-control"
                    formControlName="passwordConfirm"
                    [ngClass]="{ 'is-invalid': form.get('passwordConfirm').invalid && form.get('passwordConfirm').touched }"
                />
                <p *ngIf="form.get('passwordConfirm').invalid && form.get('passwordConfirm').touched" class="text-danger">
                    {{ "Passwords do not match" | translate }}
                </p>
            </div>
            <div class="d-flex justify-content-end">
                <button class="btn btn-primary" [disabled]="!form.valid">
                    {{ "Next" | translate }}
                </button>
            </div>
        </form>
    </div>
</ion-content>
